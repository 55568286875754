
      export default `query($name: String, $route: String, $order: MenuOrderBy) {
  menu(name: $name, pathname: $route, orderBy: $order) {
    item {
      label,
      route
    }
    children {
      item {
        label,
        route
      },
      children {
        item {
          label,
          route
        }
      }
    }
  }
}`;
    